import Button from 'components/Button'
import AppLayout from 'layouts/AppLayout'
import { _formatters } from '@redsales/ds/utils'
import { navigate } from 'gatsby'
import { useLoans } from 'hooks/useLoans'
import { useState } from 'react'
import LoanValueModal from './components/LoanValueModal'
import { useLoader } from 'hooks/useLoader'

import './LoanValue.scss'
import makeTrackings from 'utils/make/trackings'
import { loansFormId } from 'utils/make/constants'
import { handlePositionText } from 'utils/handlePositionText'

const { toCurrency } = _formatters

const LoanValue = () => {
  const { setLoanValue } = useLoans()
  const { setLoader } = useLoader()

  const [isLoanModalOpen, setIsLoanModalOpen] = useState(false)

  const simulationOptions = [5000, 10000, 20000, 30000, 50000]

  const handleSelectedLoan = (value) => {
    setLoader({
      isLoading: true,
      text: 'Estamos buscando as melhores opções para você...',
    })

    setTimeout(() => {
      makeTrackings.formContinued({
        formId: loansFormId,
        formName: 'loans',
        stepId: '9c6d9c0a-9871-49c9-9512-cd73a90bd59c',
        stepName: 'loans-ammount',
        stepNumber: 2,
      })

      setLoanValue(value)

      navigate('/emprestimos/selecao')
      setTimeout(() => {
        setLoader({ isLoading: false })
      }, 1000)
    }, 2000)
  }

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Simule um empréstimo.',
        description:
          'Os valores e condições podem mudar de acordo com a análise de crédito.',
        onTrack: () => {
          makeTrackings.formBacked({
            formId: loansFormId,
            formName: 'loans',
            stepId: '3855e4a9-a480-49e2-9cf7-30ef4597edb9',
            stepName: 'loans-ammount',
            stepNumber: 2,
          })
        },
      }}
      contentClassName="loan-value"
      containerClassName="loan-container"
    >
      {simulationOptions.map((value, index) => {
        return (
          <Button
            key={`${value}-button`}
            data-variant="ghost"
            onClick={() => {
              makeTrackings.elementClicked({
                actionOutcome: 'select-loan-ammount',
                elementType: 'button',
                htmlId: '6ff94e66-e5f2-414b-8937-9c12df4451bf',
                location: 'list',
                name: `click-form-loans-ammount-${value}`,
                position: handlePositionText(index + 1),
                text: toCurrency(value),
              })

              handleSelectedLoan(value)
            }}
          >
            {toCurrency(value)}
          </Button>
        )
      })}
      <Button data-variant="ghost" onClick={() => setIsLoanModalOpen(true)}>
        Outro valor
      </Button>

      <LoanValueModal
        isOpen={isLoanModalOpen}
        setIsOpen={setIsLoanModalOpen}
        handleSelectedLoan={handleSelectedLoan}
        otherButtonPosition={simulationOptions.length + 1}
      />
    </AppLayout>
  )
}

export default LoanValue
